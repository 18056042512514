.horizontal-div-line {
  //margin-top: 5vh;
  width: 1px;
  -webkit-box-align: center;
  align-items: center;
  background: black;
  min-height: 15vh;
  margin-left: 30px;
}

.title {
  font-weight: 600;
  font-size: 42px;
  line-height: 72px;
  //margin-left: 15px;
}

.description {
  margin-bottom: 2vh;
}

.empty {
  opacity: 0.2;
  margin-top: 10px;
  margin-bottom: 20px;
}

.number {
  margin-top: -50px;
}

.tab {
  position: -webkit-sticky;
  position: sticky;
  top: 52px;
  z-index: 1020
}

.row-wrapper {
  //overflow: hidden;
  overflow-x: scroll;
  flex-wrap: nowrap;
  width: 100vw;
  position: relative;
  display: flex;
  //padding: 20px;
  //margin: 20px;
}

.ele-char {
  display: block;
  margin: 10px;
  img {
    width: 45vw;
  }
  //padding: 15px 15px 0;
  //table-layout: fixed;
}

.ele {
  display: block;
  margin: 10px;
  img {
    width: 28vw;
    max-width: 120px;
  }
  //padding: 15px 15px 0;
  //table-layout: fixed;
}

.knowledgeOuter {
  min-height: 200px;
}

.knowledge {
  span {
    text-align: left;
    margin-top: 20px;
    margin-left: 20px;
    width: 30vw;
    position: absolute;
    z-index: 100;
    display: block;
    opacity: 0.5;
  }
}

.empty-knowledge{
  position: relative;
}


.general-popup {
  --bs-modal-bg: #000000 !important;
  opacity: 0.9;
  color: white !important;
}

.info-close {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  top: -50px;
}

.type {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: fixed;
  right: 15px;
  font-size: 48px;
  opacity: 0.2;
}

.char-img{
  height: 30vh;
  position: absolute;
  top: -20vh;
  left: 30%;
}

.name {
  font-size: 24px;
  margin-bottom: 20px;
}

.buttons {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 20%;
  bottom: -60px;

  div {
    background: #F4F3F0;
    box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(220, 219, 216, 0.9), inset 2px 2px 4px rgba(255, 255, 255, 0.9);
    border-radius: 50px;
    width: 50px;
    height: 50px;
    justify-content: center;
    padding-left: 10px;
    padding-top: 10px;
    margin-right: 20px;
  }
}

.mirror-icon {
  padding-left: 20px !important;
  width: 120px !important;
  color: black;
}

.bg {
  position: absolute;
  z-index: 0;
  top: 70px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.aixoAvatar {
  position: relative;
  z-index: 1;
  margin-top: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;

}

.title1 {
  position: relative;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  display: block;
  text-align: center;
  margin-top: 30px;
}

.title2 {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #000000;
  opacity: 0.3;
  text-align: center;
  margin-top: 15px;
}

.horizontal-div-line {
  margin-top: 5vh;
  width: 1px;
  -webkit-box-align: center;
  align-items: center;
  background: black;
  min-height: 65px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.title3 {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  color: #000000;
  opacity: 0.6;
  text-align: center;
  margin-top: 15px;
}

.input {
  background: #FFFFFF !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(230, 230, 230, 0.5), inset -5px 5px 10px rgba(230, 230, 230, 0.2), inset 5px -5px 10px rgba(230, 230, 230, 0.2), inset -5px -5px 10px rgba(255, 255, 255, 0.9), inset 5px 5px 13px rgba(230, 230, 230, 0.9);
  border-radius: 4px;
  margin-left: 45px;
  margin-bottom: 25px;
  height: 45px;
  padding: 10px 100px 10px 10px;
}

.activateBtn {
  background: black !important;
  border: 1px solid #000000;
  border-radius: 8px;
}

//
//.rectangle {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  position: fixed;
//  z-index: 0;
//}

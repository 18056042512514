@use './src/styles/animation';

.btn-wrapper {
  width: 100%;
  height: 40vh;
  margin-top: -33vh;
  left: 0;
  bottom: 0;
  margin-inline-start: auto;
  margin-inline-end: auto;
  border-radius: 20px;
  text-align: center;

  &-chat {
    margin-top: 10vh;
  }
}

.circleButtons {
  position: relative;
  display: flex;
  width: 200px;
  height: 200px;
  border-radius: 200px;
  align-items: center;
  justify-content: center;
  background: #F4F3F0;
  /*border: 1px solid rgba(45, 45, 45, 0.1);*/
  /*box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;*/
  margin: 30px;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 200px;
    height: 200px;
    content: " ";
    cursor: pointer;
    transition: 0.3s ease-out;
    border-radius: 150px;
    filter: blur(7px);
    box-shadow: 8px 8px 20px #a9a8b7, -8px -8px 20px #fff;
  }

  &:hover:before {
    box-shadow: 8px 8px 20px #a9a8b7, -8px -8px 20px #fff,
    inset -8px -8px 20px #fff, inset 8px 8px 20px #a9a8b7;
  }

  img {
    /*margin-top: 13px;*/
    width: 60%;
  }
}

.status {
  border-radius: 50%;
  background: #D13350;
  width: 12px;
  height: 12px;
  position: relative;
  top: 0px;
  right: -50px;
}

h1 {
  font-family: $font-family-serif
}
h1.cjk {
  font-family: $font-family-cjk-mincho
}
h2 {
  font-family: $font-family-sans
}
h3 {
  font-family: $font-family-serif
}
h4 {
  font-family: $font-family-mono
}
h5 {
  font-family: $font-family-sans
}
h6 {
  font-family: $font-family-mono
}
body, main {
  font-family: $font-family-sans
}

.white {
  color: $white;
}
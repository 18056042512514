.general-popup {
  --bs-modal-bg: #F4F3F0;
  border-radius: 20px;
}

.marker {
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.scan {
  transform: none !important;
  max-width: none !important;
  bottom: 0;
  right: 0;
  opacity: 1 !important;
}

.title {
  font-weight: 600;
  font-size: 16px;
}

.hint {
  font-size: 1rem;
  opacity: 0.8;
}

.popupWrapper {
  width: 100%;
  position: fixed;
  height: max-content;
  padding: 3vh 3vh 10vh;
  left: 0;
  bottom: 0;
  margin-inline-start: auto;
  margin-inline-end: auto;
  border-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-align: center;
  background: #F4F3F0;
  box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;
  display: block;
  animation: slide-up 0.3s ease;

  @keyframes slide-up {
    0% {
      bottom: -500px;
    }
    100% {
      bottom: 0;
    }
  }
}

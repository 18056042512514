.mapboxgl-popup-content {
  background: transparent !important;
  min-height: 50px;
}

.mapboxgl-control-container {
  
}

.mapboxgl-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px !important;
}
.options-wrapper {
  //height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  min-height: 170px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  //width: 327px;
  //margin-bottom: 172px;
  background: #F4F3F0;
  border: 1px solid rgba(45, 45, 45, 0.1);
  /* Neu_shadow */
  box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;
  border-radius: 20px;
  text-align: center;
  z-index: 999;
}

.initChatBox {
  height: max-content;
  display: inline-block;
  text-align: center;
  color: #666;
  font-weight: 200;
  font-size: 14px;
  font-family: letter-gothic-std, monospace;
  line-height: 24px;
  min-height: 24px;
  margin: 24px auto;
  padding: 8px 20px 10px;
  border-radius: 24px;
  background: rgba(0, 0, 0, 0);
  /* text-shadow: 0px 1px 1px rgba(255,255,2555,0.25), 0px -1px 1px rgba(0,0,0,.25); */
}

.sceneAvatar {
  position: relative;
  max-width: 50px;
  top: -8px;
}

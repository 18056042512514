@use './src/styles/shadows';

.dark, .light {
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-transform: Uppercase;
  letter-spacing: .1rem;
  align-items: center;
  padding: 15px 32px;
  gap: 10px;
/*  min-width: 40%;*/
  max-width: 95%;
  height: 54px;
  min-height: 30px;
  margin: 20px auto;
  transition: .5s ease-in-out;

    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
}

.dark {
  &.primary {
    background: #333;
    border: none;
    border-radius: 50px;
    //box-shadow: -13px 13px 26px rgba(28, 28, 28, 0.05), 13px -13px 26px rgba(28, 28, 28, 0.05), -13px -13px 26px rgba(52, 52, 52, 0.05), 13px 13px 33px rgba(28, 28, 28, 0.05), inset 1px 1px 2px rgba(52, 52, 52, 0.05), inset -1px -1px 2px rgba(28, 28, 28, 0.05);
    box-shadow: -6px -6px 14px rgba(255, 255, 255, .7),
              -6px -6px 10px rgba(255, 255, 255, .5),
              6px 6px 8px rgba(255, 255, 255, .075),
              6px 6px 10px rgba(0, 0, 0, .15);

      color: #efefef;
  }

  &.secondary {
    background: none;
    border: 2px solid #aaa;
    border-radius: 50px;
  }

  &:hover {
    background: #222;
    box-shadow: -2px -2px 6px rgba(255, 255, 255, .6),
              -2px -2px 4px rgba(255, 255, 255, .4),
              2px 2px 2px rgba(255, 255, 255, .05),
              2px 2px 4px rgba(0, 0, 0, .1);
  }
  &:active {
    box-shadow: inset -2px -2px 6px rgba(255, 255, 255, .7),
              inset -2px -2px 4px rgba(255, 255, 255, .5),
              inset 2px 2px 2px rgba(255, 255, 255, .075),
              inset 2px 2px 4px rgba(0, 0, 0, .15);
  }
}

.light {
  &.primary {
    background: #efefef; //linear-gradient(180deg, #515151 0%, #161616 100%);
    border-radius: 50px;
    border: none;
    //box-shadow: -5px 5px 10px rgba(212, 211, 209, 0.9), 5px -5px 10px rgba(212, 211, 209, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(212, 211, 209, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(212, 211, 209, 0.5);
    box-shadow: -6px -6px 14px rgba(255, 255, 255, .7),
              -6px -6px 10px rgba(255, 255, 255, .5),
              6px 6px 8px rgba(255, 255, 255, .075),
              6px 6px 10px rgba(0, 0, 0, .15);

      color: #212121;

    &:hover {
    box-shadow: -2px -2px 6px rgba(255, 255, 255, .6),
              -2px -2px 4px rgba(255, 255, 255, .4),
              2px 2px 2px rgba(255, 255, 255, .05),
              2px 2px 4px rgba(0, 0, 0, .1);
    }
    &:active {
      box-shadow: inset -2px -2px 6px rgba(255, 255, 255, .7),
                inset -2px -2px 4px rgba(255, 255, 255, .5),
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
    }
  }

  &.secondary {
    background: none;
    border: 2px solid #efefef;
    border-radius: 50px;

      color: #666;

    &:hover {
      border: 2px solid #fff;

        color: #212121;
    }
  }
}

.dark {
  &.artifact {
    background: #F4F3F0;
    border-radius: 50px;
    box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;

      color: #B89005;
      font-weight: 500;
  }
}

.light {
  &.artifact {
    background: linear-gradient(180deg, #515151 0%, #161616 100%);
    border-radius: 50px;
    box-shadow: inset 0px -3px 3px rgba(0, 0, 0, 0.1), inset 0px 6px 6px #585351;
    //box-shadow: 0 0 60px 30px rgba(255, 239, 200, 0.2), /* inner white */
    //0 0 100px 60px rgba(255, 239, 200, 0.2), /* middle magenta */
    //0 0 140px 90px rgba(255, 233, 177, 0.2); /* outer cyan */

      color: #F4F3F0;
  }
}

@media (max-width: 432px) {
  .dark, .light {
    width: 100%;
  }
}

.camera-btn {
  padding-bottom: calc(0.5rem + var(--safe-area-inset-bottom)) !important;
  position: fixed;
  bottom: 0px;
  min-height: 69px !important;
  width: 69px !important;
  background: #F4F3F0 !important;
  border: 1px solid #2D2D2D;
  border-radius: 100px !important;
  left: 50%;
  // margin-left: -35px;
  transform: translate(-50%, -50%);
  padding: 0 0 20px;
  box-shadow: shadows.inset-shadow();
  z-index: 80;

  img, span {
    opacity: 0.5;
  }
}

.suppressed {
  box-shadow: none;
}
.missionLayout {
  margin-bottom: 169px;
}

.keyImg {
  display: block;
  margin-top: 10px;
  top: 18vh;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: -1;
  max-width: 100%;
}

.subtitle {
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-top: 23px;
}

.title {
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin-top: 5px;
}

.stickyFooter {
  padding-bottom: calc(0.5rem + var(--safe-area-inset-bottom)) !important;
  position: fixed;
  bottom: 0;
  min-height: 169px;
  background: linear-gradient(180deg, rgba(244, 243, 240, 0) 0%, #F4F3F0 26.51%);
  //max-width: 60ch;
  //margin-inline-start: auto;
  //margin-inline-end: auto;

  .icon {
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 20px;
    padding-top: 10px;
  }
}

.description {
  //margin: -60px auto 30px;
  margin-left: 30px;
  margin-bottom: 10px;
  margin-top: -15px;
  padding: 10px;
  width: 327px;
  background: linear-gradient(95.33deg, rgba(255, 253, 245, 0.82) 12.87%, rgba(249, 247, 241, 0) 33.67%, rgba(244, 243, 240, 0) 69.65%, #FFFFFF 96.08%);
  border-radius: 8px;
  font-size: 14px;

  @media screen and (min-width: 500px) {
    margin-left: 40vw;
  }
}

.aixoAvatar {
  margin-top: -65px;
  //margin-bottom: px;
  margin-left: 20px;

  @media screen and (min-width: 500px) {
    margin-left: 40vw;
  }
}
.ImgWrapper {
  width: 100%;

  @media screen and (min-width: 500px) {
      width: 50%
    }
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 1px;
  background-color: black;
  padding: 1px;

  .second {
    grid-column-start: 2;
    grid-column-end: 10;
  }

  .third {
    grid-column-start: 6;
    grid-column-end: 10;
  }

  .grid-item {
    grid-column-start: 1;
    grid-column-end: 6;
  }

  div {
    background-color: #F4F3F0;
    text-align: center;
    padding: 1px 0;
    font-size: 16px;
  }

  .fifth {
    grid-column-start: 1;
    grid-column-end: 5;
  }
  .sixth {
    grid-column-start: 5;
    grid-column-end: 9;
  }



  //.row {
  //  &:nth-child(1) {
  //    border-top: 1px solid #000000;
  //  }
  //
  //  border-top: 0.5px solid #000000;
  //  border-bottom: 0.5px solid #000000;
  //  min-height: 50px;
  //  --bs-gutter-x: 2rem;
  //}
  //
  //.col {
  //  &:nth-child(1) {
  //    border-left: 0.5px solid #000000;
  //  }
  //  span {
  //    font-weight: 600;
  //    font-size: 14px;
  //    line-height: 20px;
  //    color: #030303;
  //  }
  //
  //  padding-top: 13px;
  //  padding-bottom: 13px;
  //  border-right: 0.5px solid #000000;
  //  //border-left: 0.5px solid #000000;
  //}

  span {
    display: block;

    &.play {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      letter-spacing: 0.03em;
    }

    &.time {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

.startBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 32px;
  gap: 10px;
  background: linear-gradient(180deg, #515151 0%, #161616 100%);
  box-shadow: -5px 5px 10px rgba(212, 211, 209, 0.2), 5px -5px 10px rgba(212, 211, 209, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(212, 211, 209, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(212, 211, 209, 0.5);
  border-radius: 50px;
  width: 310px;
  margin: auto;

  span {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #F4F3F0;
  }
}

.xp {
  text-align: center;
  display: block;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  padding-top: 14px;
  margin: 0;
}

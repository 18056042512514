@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  21% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  21% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  21% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes breathe {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.25;
  }
}
@-moz-keyframes breathe {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.25;
  }
}
@keyframes breathe {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.25;
  }
}

@keyframes flashAnimation {
  0% {
    background-color: white;
  }
  50% {
    background-color: white;
  }
  100% {
    background-color: initial; /* or you can set it to the original background color */
  }
}

.flash {
  background: transparent;
  width: 100%;
  height: 100%;
  z-index: 80;
  position: absolute;
  top: 0;
  left: 0;
}

.flash-effect {
  animation: flashAnimation 0.5s ease-out; /* Adjust the duration and easing as needed */
}

/* Glitch styles */

.glitch {
/*  position: absolute;*/
  top: 0;
  left: 0;
  width: var(--glitch-width);
  height: var(--glitch-height);
  overflow: hidden;
}

.chat .glitch__img {
  left: calc(50vw - 50vh);
}

.glitch__img {
  position: absolute;
  top: calc(-1 * var(--gap-vertical));
  left: calc(-1 * var(--gap-horizontal));
  width: calc(100% + var(--gap-horizontal) * 2);
  height: calc(100% + var(--gap-vertical) * 2);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-color: var(--blend-color-1);
  background-size: cover;
  transform: translate3d(0,0,0);
  background-blend-mode: var(--blend-mode-1);
  mix-blend-mode: var(--blend-mode-1);
}

/*.glitch__img:nth-child(n+2) {
  opacity: 0;
}*/

.glitch__img:nth-child(n+2) {
  animation-duration: var(--time-anim);
  animation-delay: var(--delay-anim);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.glitch__img:nth-child(2) {
  background-color: var(--blend-color-2);
  mix-blend-mode: var(--blend-mode-2);
  animation-name: glitch-anim-1;
}

.glitch__img:nth-child(3) {
  background-color: var(--blend-color-3);
  mix-blend-mode: var(--blend-mode-3);
  animation-name: glitch-anim-2;
}

.glitch__img:nth-child(4) {
  background-color: var(--blend-color-4);
  mix-blend-mode: var(--blend-mode-4);
  animation-name: glitch-anim-3;
}

.glitch__img:nth-child(5) {
  background-color: var(--blend-color-5);
  mix-blend-mode: var(--blend-mode-5);
  animation-name: glitch-anim-flash;
}

/* Animations */

@keyframes glitch-anim-1 {
  0% {
    opacity: 1;
    transform: translate3d(var(--gap-horizontal),0,0);
    -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
  }
  2% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
  }
  4% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
  }
  6% {
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
  }
  8% {
    -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
  }
  10% {
    -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
  }
  12% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
  }
  14% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
  }
  16% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
  18% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
  }
  20% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
  }
  21.9% {
    opacity: 1;
    transform: translate3d(var(--gap-horizontal),0,0);
  }
  22%, 100% {
    opacity: 0;
    transform: translate3d(0,0,0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
}

@keyframes glitch-anim-2 {
  0% {
    opacity: 1;
    transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
    -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
  }
  3% {
    -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
  }
  5% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
  }
  7% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  9% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
  }
  11% {
    -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
  }
  13% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  15% {
    -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
  }
  17% {
    -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
  }
  19% {
    -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
  }
  20% {
    -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
  }
  21.9% {
    opacity: 1;
    transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0);
  }
  22%, 100% {
    opacity: 0;
    transform: translate3d(0,0,0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
}

@keyframes glitch-anim-3 {
  0% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1);
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
  }
  1.5% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
  }
  2% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
  }
  2.5% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  3% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
  }
  5% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
  }
  5.5% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
  }
  7% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
  }
  8% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
  }
  9% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
  }
  10.5% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
  }
  11% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
  }
  13% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
  }
  14% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
  }
  14.5% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
  }
  15% {
    -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
  }
  16% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  18% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
  }
  20% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
  }
  21.9% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1,-1,1);
  }
  22%, 100% {
    opacity: 0;
    transform: translate3d(0,0,0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
}

@keyframes glitch-anim-text {
  0% {
    transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0) scale3d(-1,-1,1);
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
  }
  2% {
    -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
  }
  4% {
    -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
  }
  5% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
  }
  6% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
  }
  7% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
  8% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
  }
  9% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
  }
  9.9% {
    transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0) scale3d(-1,-1,1);
  }
  10%, 100% {
    transform: translate3d(0,0,0) scale3d(1,1,1);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}

/* Flash */

@keyframes glitch-anim-flash {
  0%, 5% {
    opacity: 0.2;
    transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0);
  }
  5.5%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes glowing {
  0% {
    //width: 40px;
    //height: 40px;
    border-radius: 50%;
    background-color: rgba(255, 239, 200, 0.05);
    box-shadow: 0 0 60px 30px rgba(255, 239, 200, 0.05), /* inner white */
    0 0 100px 60px rgba(255, 239, 200, 0.05), /* middle magenta */
    0 0 140px 90px rgba(255, 233, 177, 0.05); /* outer cyan */
  }

  50% {
    background-color: rgba(255, 233, 177, 0.3);
    border-radius: 50%;
    box-shadow: 0 0 60px 30px rgba(255, 239, 200, 0.1), /* inner white */
    0 0 100px 60px rgba(255, 239, 200, 0.1), /* middle magenta */
    0 0 140px 90px rgba(255, 233, 177, 0.1); /* outer cyan */
  }
  75% {
    background-color: rgba(255, 233, 177, 0.3);
    border-radius: 50%;
    box-shadow: 0 0 60px 30px rgba(255, 239, 200, 0.15), /* inner white */
    0 0 100px 60px rgba(255, 239, 200, 0.15), /* middle magenta */
    0 0 140px 90px rgba(255, 233, 177, 0.15); /* outer cyan */
  }

  100% {
    background-color: rgba(255, 239, 200, 0.2);
    border-radius: 50%;
    box-shadow: 0 0 60px 30px rgba(255, 239, 200, 0.2), /* inner white */
    0 0 100px 60px rgba(255, 239, 200, 0.2), /* middle magenta */
    0 0 140px 90px rgba(255, 233, 177, 0.2); /* outer cyan */
  }
}

.glowing-effect {
  animation: glowing 1000ms ease-in;
  animation-fill-mode: forwards;
  //opacity: 0.81;
  width: 130px;
  height: 130px;
  background-color: #FFFFFE;
  margin: 20px auto;
}

@keyframes shading {
  0% {
    border-radius: 50%;
    background-color: rgba(0, 10, 50, 0.05);
    box-shadow: 0 0 60px 30px rgba(0, 10, 50, 0.05),
    0 0 100px 60px rgba(0, 11, 50, 0.05),
    0 0 140px 90px rgba(0, 17, 80, 0.05);
  }

  50% {
    background-color: rgba(0, 10, 80, 0.3);
    border-radius: 50%;
    box-shadow: 0 0 60px 30px rgba(0, 10, 50, 0.1),
    0 0 100px 60px rgba(0, 10, 50, 0.1),
    0 0 140px 90px rgba(0, 20, 80, 0.1);
  }
  75% {
    background-color: rgba(0, 15, 80, 0.3);
    border-radius: 50%;
    box-shadow: 0 0 60px 30px rgba(0, 10, 50, 0.15), /* inner white */
    0 0 100px 60px rgba(0, 10, 50, 0.15),
    0 0 140px 90px rgba(0, 20, 80, 0.15);
  }

  100% {
    background-color: rgba(0, 10, 50, 0.2);
    border-radius: 50%;
    box-shadow: 0 0 60px 30px rgba(0, 10, 50, 0.2), /* inner white */
    0 0 100px 60px rgba(0, 10, 50, 0.2), /* middle magenta */
    0 0 140px 90px rgba(0, 20, 80, 0.2); /* outer cyan */
  }
}

.shade-effect {
  animation: shading 1000ms ease-in;
  animation-fill-mode: forwards;
  //opacity: 0.81;
  width: 250px;
  height: 250px;
  background-color: #333333;
  margin: auto auto;
}

.luminaire {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 200s alternate infinite;
  pointer-events: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  &::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }

  &::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite;
  }
}

.firefly {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0.5vw;
  height: 0.5vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 1s alternate infinite;
  pointer-events: none;

  &::before, &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }

  &::before {
    background: #efefef;
    opacity: 0.8;
    animation: drift ease alternate infinite;
  }

  &::after {
    background: white;
    opacity: 1;
    box-shadow: 0 0 0vw 0vw white;
    animation: drift ease alternate infinite, flash ease infinite;
  }
}


// Randomize Fireflies Motion
@for $i from 1 through 30 {
  $steps: random(12) + 16;
  $rotationSpeed: random(10) + 8s;

  .firefly:nth-child(#{$i}) {
    animation-name: move#{$i};

    &::before {
      animation-duration: #{$rotationSpeed}
    }

    &::after {
      animation-duration: #{$rotationSpeed}, random(6000) + 5000ms;
      animation-delay: 0ms, random(8000) + 500ms
    }
  }

  @keyframes move#{$i}{
    @for $step from 0 through $steps {
      #{$step * (100 / $steps)}% {
        transform: translateX(random(100) -50 vw) translateY(random(100) - 50 vh) scale(random(75) / 100 + .25)
      }
    }
  }
}

@keyframes drift {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@keyframes flash {
  0%, 30%, 100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #FFEEDD
  }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw #FFEEEE
  }
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.modal-content {
  border-radius: 20px !important;
  background-color: white;
}

.game-popup .modal-content {
  background: none;
  box-shadow: none;
  min-height: 15vh;
}

.chat-popup .modal-content {
  background: #efefef;
}

.fullScreen .modal-content {
  border-radius: 0 !important;
  background: #efefef;
}

/* make the customizations */


/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

#arjs-video {
  object-fit: cover;
  z-index: 2 !important;
}

canvas {
  width: max-content !important;
}

.slick-slide img {
  margin: auto;
}

.slick-list {
  height: 100vh !important;
}

.slick-dots {
  position: fixed;
  right: 44vw;
  bottom: 50vh;
  rotate: 90deg;

  li.slick-active button:before {
    color: #C2C2C2;
    opacity: 1;
    font-size: 10px;
  }

  li button:before {
    color: #E2E2DF;
    opacity: 1;
    font-size: 10px;
  }

}

//mapbox
.mapboxgl-popup-tip {
  display: none;
}
html, body {
  overscroll-behavior: none;
}

//anti-spoiler
.cs-message__custom-content{
  s {
    background-color: #0d0d0d;
    color: #0d0d0d;
    text-decoration: none;

    &:hover {
      background-color: #808080;
    }
  }
}

.loader {
  //height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  //height: 3rem;

  &.fit-content {
    //height: 5rem;
  }
}

@use './src/styles/shadows';

.camera-btn {
  padding-bottom: calc(0.5rem + var(--safe-area-inset-bottom)) !important;
  position: fixed;
  bottom: 0;
  min-height: 69px !important;
  width: 69px !important;
  background: #F4F3F0 !important;
  border: 1px solid #2D2D2D;
  border-radius: 100px !important;
  left: 50vw;
  margin-left: -35px;
  padding: 0 0 20px;
  box-shadow: shadows.inset-shadow();

  img, span {
    opacity: 0.5;
  }
}

.groupPhoto {
  width: 171px;
  height: 238px;
  object-fit: cover;
  margin-top: 10px;
}

.instant {
  display: flex;
  justify-content: center;
  width: 191px;
  height: 347px;
  box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;
}

.center {
  display: flex;
  justify-content: center;
}

.title {
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  writing-mode: vertical-rl;
  margin-top: 40px;
  margin-bottom: 40px;
}

.instantCaption {
  background: linear-gradient(95.33deg, rgba(255, 253, 245, 0.82) 12.87%, rgba(249, 247, 241, 0) 33.67%, rgba(244, 243, 240, 0) 69.65%, #FFFFFF 96.08%);
  border-radius: 8px;
  margin: 30px;
  padding: 20px;
}

.scroller {
  height: 100vh;
  overflow: scroll;
  scroll-snap-type: y mandatory;
  //display: flex;
  justify-content: center;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .section {
    max-width: 500px;
    height: 100vh;
    scroll-snap-align: center;
    overflow-y: hidden;
  }
}

.box {
  border: 1px solid #000000;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  margin: 30px;
  padding: 10px;
}

.vertical-div-line {
  margin-top: 10px;
  width: 2px;
  -webkit-box-align: center;
  align-items: center;
  background: #000000;
  min-height: 15vh;

}

.caption {
  background: linear-gradient(95.33deg, rgba(255, 253, 245, 0.82) 12.87%, rgba(249, 247, 241, 0) 33.67%, rgba(244, 243, 240, 0) 69.65%, #FFFFFF 96.08%);
  border-radius: 8px;
  margin-top: 30px;
  padding: 20px;
}

.subtitle {
  font-weight: 900;
  font-size: 40px;
  margin-top: 10px;
}

.verticalWriting {
  writing-mode: vertical-rl;
}

.finaleButton {
  max-width: 500px;
  margin: 2px !important;
  background: #D9D9D9 !important;
  border-radius: 46px !important;
  color: black !important;
  border: none !important;
  height: 92px !important;
  font-weight: 700 !important;
  box-shadow: shadows.inset-shadow();
}

.reviewButton {
  max-width: 500px;
  margin: 2px !important;
  background: #D9D9D9 !important;
  border-radius: 46px !important;
  color: black !important;
  border: none !important;
  height: 92px !important;
  font-weight: 700 !important;
  box-shadow: shadows.raised-shadow();
}


.popup-body {
  height: 428px;
  justify-content: center;
  text-align: center;
}

.exp {
  font-weight: 600;
  font-size: 36px;
  line-height: 68px;
  margin-bottom: 10px;
}

.popupTitle {
  font-weight: 300;
  font-size: 40px;
  writing-mode: vertical-rl;
  margin-top: 40px;
  margin-bottom: 40px;
  border-left: 2px solid black;
  border-right: 2px solid black;
}

.yuenTitle {
  font-weight: 300;
  font-size: 40px;
  writing-mode: vertical-rl;
  margin-top: 40px;
  margin-bottom: 40px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  padding-top: 100px;
  padding-bottom: 100px;
}

.ScrollSection {
  text-align: center;
  justify-content: center;
  padding: 10vh 0;
  height: 90vh;
  //max-width: 400px;
  //display: flex;
  //left: 0;
  //right: 0;
  //margin-left: auto;
  //margin-right: auto;
}

.background {
  opacity: 0.2;

  img {
    display: inline-block !important;
  }
}

.number {
  position: relative;
  top: -80px;
  left: -20px;
  z-index: 1;
  color: #F4F3F0;
  fill: none;
  font-size: 48px;
  -webkit-text-stroke: 2px #212121;
}

.characterRow {
  .row:nth-child(even) > .col:first-child {
    order: 2;
  }

  .row:nth-child(odd) > .col:first-child {
    order: 1;
  }

  .row:nth-child(even) > .col:last-child {
    order: 1;
  }

  .row:nth-child(odd) > .col:last-child {
    order: 2;
  }
}

.postcardPhoto {
  width: 136px;
  height: 183px;
  object-fit: cover;
  margin-top: 10px;
}

.postcard {
  display: flex;
  justify-content: center;
  z-index: -1;
  border: 8px solid #FFFFFF;
  background-color: #FFFFFF;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
}

.post {
  position: absolute;
  top: 255px;
  right: 150px;
}

.circleButtons {
  z-index: 1;
  display: flex;
  width: max-content;
  height: max-content;
  background: #F4F3F0;
  border: 1px solid rgba(45, 45, 45, 0.1);
  box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;
  border-radius: 100px;
  margin: 10px;
  padding: 13px;
}

.download {
  z-index: 1;
  display: flex;
  position: absolute;
  bottom: 30px;
  width: max-content;
  height: max-content;
  background: #F4F3F0;
  border: 1px solid rgba(45, 45, 45, 0.1);
  box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;
  border-radius: 100px;
  margin: 10px;
  padding: 13px;
}


.info-close {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  top: -50px;
}

.buttons {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 20%;
  bottom: -60px;

  div {
    background: #F4F3F0;
    box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(220, 219, 216, 0.9), inset 2px 2px 4px rgba(255, 255, 255, 0.9);
    border-radius: 50px;
    width: 50px;
    height: 50px;
    justify-content: center;
    padding-left: 10px;
    padding-top: 10px;
    margin-right: 20px;
  }
}


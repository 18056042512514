.stickyFooter {
  padding-bottom: calc(0.5rem + var(--safe-area-inset-bottom)) !important;
  position: fixed;
  bottom: 0;
  min-height: 169px;
  background: linear-gradient(180deg, rgba(244, 243, 240, 0) 0%, #F4F3F0 26.51%);
  max-width: 55ch;
  color: #F4F3F0;

  img,span {
    opacity: 0.5;
  }


  .icon {
    display: flex;
    text-align: center;
    justify-content: center;
    margin: 20px;
    padding-top: 10px;
  }
}


.navbar {
  background: #000000;
  border-radius:20px 20px 0 0;
  height: 96px;
  color: #F4F3F0;
  width: auto;
}

.active {
  img,span {
    opacity: 1;
  }
  color: #F4F3F0;
  border-radius: 0px 0px 4px 4px;
}

.mark {
  position: absolute;
  width: 30px;
  height: 7px;
  background: #F4F3F0;
  border-radius: 0px 0px 4px 4px;
  bottom: 90px;
}
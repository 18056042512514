// Color system
$primary: #F4F3F0;
$secondary: #212121;
$success: #8BC53F;
$warning: #D0AF6B;
$danger: #A93B3B;

$min-contrast-ratio: 3;
$white: #efefef;
$black: #212121;

$gray-600: #646464;
$gray-200: #efefef;
$body-color: $gray-200;

// Grid columns
$grid-gutter-width: 1.75rem;

// Typography
$font-family-sans: din-2014, sans-serif;
$font-family-serif: adobe-garamond-pro, serif;
$font-family-mono: letter-gothic-std, monospace;
$font-family-game: ab-megadot9, sans-serif;
$font-family-cjk: a-otf-ryumin-pr6n, serif;
$font-family-cjk-mincho: shippori-mincho-b1, serif;

$font-size-base: 1.1rem;
$font-weight-normal: 500;

$h1-font-size: $font-size-base * 4;
$h2-font-size: $font-size-base * 2.5;
$h3-font-size: $font-size-base * 2;
$h4-font-size: $font-size-base * 1.75;
$h5-font-size: $font-size-base * 1.5;
$h6-font-size: $font-size-base * 1.25;

$headings-margin-bottom: 1rem;
//$headings-font-family: 'Hahmlet', 'Noto Serif TC', serif;
$headings-color: #232323;

$paragraph-margin-bottom: 1.5rem;
$blockquote-margin-y: 3rem;

// Buttons + Forms
$input-btn-focus-width: 0.075rem;

// Navbar
$navbar-padding-y: 0.25rem;

$navbar-dark-color: rgba($white, 0.95);
$navbar-dark-hover-color: rgba($white, 0.75);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.25);

$navbar-light-color: rgba($black, 0.95);
$navbar-light-hover-color: rgba($black, 0.75);
$navbar-light-active-color: $black;
$navbar-light-disabled-color: rgba($black, 0.25);

// Card
$card-border-color: rgba($black, 0.05);
$card-border-radius: 0.5rem;
@use './src/styles/shadows';
@use './src/styles/animation';

.top {
  margin-top: 5vh;
}

.artifact-popup {
  --bs-modal-bg: rgba(0, 0, 0, 0.8) !important;
  //background-color: rgba(0, 0, 0, 0.6);
  color: #212121;
  display: block;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.exp {
  font-weight: 600;
  font-size: 40px;
  line-height: 68px;
  margin-bottom: 10px;
}

.name {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.reminder {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  opacity: 0.5;
  margin-bottom: 30px;
}
                                                                                                                                                                                                                                                                                e
.artifactimg {
  position: relative;
  top: -5px;
  left: -5px;
}

.general-popup {
  --bs-modal-bg: #F4F3F0;
  border-radius: 20px;
}

.popup-body {
  min-height: 128px;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(244, 243, 240, 0) 0%, #F4F3F0 26.51%);

  &--coupon {
    border-radius: 20px;
  }
}

.popup-body-collapse {
  min-height: 28px;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}
.popup-body-left {
  justify-content: left;
  text-align: left;
  min-height: 564px;
}

.question-box {
  background: linear-gradient(135deg, #E8E7E4 0%, #FFFFFC 100%);
  box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;
  border-radius: 20px;
  width: 90px;
  height: 90px;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  margin-top: -20px;
  left: 50%;
  /*top: 50%;*/
  transform: translate(-50%, -50%);
}

//.question-icon {
//  position: relative;
//  margin-top: -50px;
//}

.info-close {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50px;
}

.answer {
  background: #F4F3F0;

  div {
    padding-top: 8px;
  }
}

.clicked {
  background: #FFFFFF;

  div {
    padding-top: 8px;
  }
}

.checkxCircle {
  padding-top: 2.3em;
}

.error {
  color: #A93B3B;
  padding-top: 20px;
  display: block;
}

.hints {
  display: inline-block;
  background: rgba(116, 111, 105, 0.8);
  border-radius: 20px;
  width: 72px;
  color: #FFFFFF;
  cursor: pointer;
}

.hintsBox {
  position: fixed;
  bottom: 30px;
  left: 0;
  width: 100%;
  text-align: center;
}

.activeDot {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: #000000;
  border-radius: 50%;
  margin-left: 4px;
  margin-top: 8px;
  margin-bottom: 8px;

  span {
    color: #FFFFFC;
    vertical-align: top;
  }
}

.inactiveDot {
  display: inline-block;
  width: 25px;
  height: 25px;
  opacity: 0.5;
  border: 1px solid #2D2D2D;
  border-radius: 50%;
  margin-left: 4px;
  margin-top: 8px;
  margin-bottom: 8px;

  span {
    vertical-align: top;
  }
}

.chat-name {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  text-align: left;
}

.activeImg {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.title {
  text-align: center;
  z-index: 99;
}

.activeInput {
  margin-top: -5vh;
  //z-index: 999;
}

.activateBtn {
  background: black !important;
  border: 1px solid #000000;
  border-radius: 8px;
  width: 60px !important;
  margin: 0 !important;
}

.inputContainer {
  max-width: 80%;
  margin: 20px auto;
}

.input {
  background: #FFFFFF !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(230, 230, 230, 0.5), inset -5px 5px 10px rgba(230, 230, 230, 0.2), inset 5px -5px 10px rgba(230, 230, 230, 0.2), inset -5px -5px 10px rgba(255, 255, 255, 0.9), inset 5px 5px 13px rgba(230, 230, 230, 0.9);
  border-radius: 50px;
  margin: 10px 0px 0;
  padding: 10px;
  height: 54px;
  width: 310px !important;
}

.text {
  display: flex;
  justify-content: center;
  margin: 0;
  padding-top: 5px;
}

.submitBtn {
  height: 54px;
  background: linear-gradient(180deg, #515151 0%, #161616 100%);
  box-shadow: -5px 5px 10px rgba(212, 211, 209, 0.2), 5px -5px 10px rgba(212, 211, 209, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(212, 211, 209, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(212, 211, 209, 0.5);
  border-radius: 50px;

  span {
    color: #F4F3F0;
  }
}

.subTitle {
  display: flex;
  justify-content: left;
  padding-top: 5px;
  margin-left: 35px;
}

.circleBtn {
  &-left, &-right {
    box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding-top: 6px;
    position: absolute;
    background-color: #F4F3F0;
    top: 200px;
  }

  &-left {
    left: -8px;
  }

  &-right {
    right: -8px;
  }
}

.unlockWrapper {
  min-height: 300px;
}

.options-wrapper {
  //height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  min-height: 170px;
  margin-inline-start: auto;
  margin-inline-end: auto;
  //width: 327px;
  //margin-bottom: 172px;
  background: #F4F3F0;
  border: 1px solid rgba(45, 45, 45, 0.1);
  /* Neu_shadow */
  box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;
  border-radius: 20px;
  text-align: center;
  z-index: 999;
}

.popup-btn , .popup-btn-alt {
  background: #2D2D2D;
  box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.8), inset 2px 2px 4px #6D6B6B;
  border-radius: 50px;
  width: 230px;
  height: 50px;
  //align-items: center;
  //justify-content: center;
  padding: 10px 32px;
  //margin: 10px;
  gap: 10px;
  color: white;
  align-self: flex-end;
  margin-top: auto;
  display: table;

  span {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    color: white;
  }

  &-alt {
    border: 1px solid #2D2D2D;
    background: transparent;
    box-shadow: none;

    span {
      color: black;
    }

  }
}

.btnWrapper{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.loginModal {
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 500px){
    max-width: 35vw;
  }
}

.center {
  display: flex;
  justify-content: center;
}

.popupTitle {
  font-weight: 300;
  font-size: 24px;
  writing-mode: vertical-rl;
  margin-top: 40px;
  margin-bottom: 40px;
  border-left: 2px solid black;
  border-right: 2px solid black;
}

.popup-question {

}

.popup-input {

}

/*final styling*/


.groupPhoto {
  width: 171px;
  height: 238px;
  object-fit: cover;
  margin-top: 10px;
}

.instant {
  display: flex;
  justify-content: center;
  width: 191px;
  height: 347px;
  box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;
}

.center {
  display: flex;
  justify-content: center;
}

.title {
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  writing-mode: vertical-rl;
  margin-top: 40px;
  margin-bottom: 40px;
}

.instantCaption {
  background: linear-gradient(95.33deg, rgba(255, 253, 245, 0.82) 12.87%, rgba(249, 247, 241, 0) 33.67%, rgba(244, 243, 240, 0) 69.65%, #FFFFFF 96.08%);
  border-radius: 8px;
  margin: 30px;
  padding: 20px;
}

.scroller {
  height: 100vh;
  overflow: scroll;
  scroll-snap-type: y mandatory;
  //display: flex;
  justify-content: center;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .section {
    max-width: 500px;
    height: 100vh;
    scroll-snap-align: center;
    overflow-y: hidden;
  }
}

.box {
  border: 1px solid #000000;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  margin: 30px;
  padding: 10px;
}

.vertical-div-line {
  margin-top: 10px;
  width: 2px;
  -webkit-box-align: center;
  align-items: center;
  background: #000000;
  min-height: 15vh;

}

.caption {
  background: linear-gradient(95.33deg, rgba(255, 253, 245, 0.82) 12.87%, rgba(249, 247, 241, 0) 33.67%, rgba(244, 243, 240, 0) 69.65%, #FFFFFF 96.08%);
  border-radius: 8px;
  margin-top: 30px;
  padding: 20px;
}

.subtitle {
  font-weight: 900;
  font-size: 40px;
  margin-top: 10px;
}

.verticalWriting {
  writing-mode: vertical-rl;
}

.finaleButton {
  max-width: 500px;
  margin: 2px !important;
  background: #D9D9D9 !important;
  border-radius: 46px !important;
  color: black !important;
  border: none !important;
  height: 92px !important;
  font-weight: 700 !important;
  box-shadow: shadows.inset-shadow();
}

.reviewButton {
  max-width: 500px;
  margin: 2px !important;
  background: #D9D9D9 !important;
  border-radius: 46px !important;
  color: black !important;
  border: none !important;
  height: 92px !important;
  font-weight: 700 !important;
  box-shadow: shadows.raised-shadow();
}


.popup-body {
/*  height: 428px;*/
  justify-content: center;
  text-align: center;
}

.exp {
  font-weight: 600;
  font-size: 36px;
  line-height: 68px;
  margin-bottom: 10px;
}

.popupTitle {
  font-weight: 300;
  font-size: 40px;
  writing-mode: vertical-rl;
  margin-top: 40px;
  margin-bottom: 40px;
  border-left: 2px solid black;
  border-right: 2px solid black;
}

.yuenTitle {
  font-weight: 300;
  font-size: 40px;
  writing-mode: vertical-rl;
  margin-top: 40px;
  margin-bottom: 40px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  padding-top: 100px;
  padding-bottom: 100px;
}

.ScrollSection {
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  padding: 10vh 0;
  height: 90vh;
  display: table-cell;
  width: 100vw;
  //display: flex;
  //left: 0;
  //right: 0;
  //margin-left: auto;
  //margin-right: auto;
}

.retakeBtn {
  position: absolute;
  z-index: 999;
  top: 8vh;
  margin-left: 15px;

  padding-bottom: calc(0.5rem + var(--safe-area-inset-bottom)) !important;
  min-height: 32px !important;
  width: 32px !important;
  background: #F4F3F0 !important;
  border: 1px solid #2D2D2D;
  border-radius: 100px !important;
}

.background {
  opacity: 0.2;

  img {
    display: inline-block !important;
  }
}

.number {
  position: relative;
  top: -80px;
  left: -20px;
  z-index: 1;
  color: #F4F3F0;
  fill: none;
  font-size: 48px;
  -webkit-text-stroke: 2px #212121;
}

.characterRow {
  .row:nth-child(even) > .col:first-child {
    order: 2;
  }

  .row:nth-child(odd) > .col:first-child {
    order: 1;
  }

  .row:nth-child(even) > .col:last-child {
    order: 1;
  }

  .row:nth-child(odd) > .col:last-child {
    order: 2;
  }
}

.postcardPhoto {
  width: 136px;
  height: 183px;
  object-fit: cover;
  margin-top: 10px;
}

.postcard {
  display: flex;
  justify-content: center;
  z-index: 1;
  border: 8px solid #FFFFFF;
  background-color: #FFFFFF;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  animation: fadeIn 10s;
}

.post {
  position: absolute;
  top: 255px;
  right: 150px;
}

.circleButtons {
  z-index: 1;
  display: flex;
  width: max-content;
  height: max-content;
  background: #F4F3F0;
  border: 1px solid rgba(45, 45, 45, 0.1);
  box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;
  border-radius: 100px;
  margin: 10px;
  padding: 13px;
}

.download {
  z-index: 1;
  display: flex;
  position: absolute;
  bottom: 30px;
  width: max-content;
  height: max-content;
  background: #F4F3F0;
  border: 1px solid rgba(45, 45, 45, 0.1);
  box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;
  border-radius: 100px;
  margin: 10px;
  padding: 13px;
}


.info-close {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  top: -50px;
}

.buttons {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 20%;
  bottom: -60px;

  div {
    background: #F4F3F0;
    box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(220, 219, 216, 0.9), inset 2px 2px 4px rgba(255, 255, 255, 0.9);
    border-radius: 50px;
    width: 50px;
    height: 50px;
    justify-content: center;
    padding-left: 10px;
    padding-top: 10px;
    margin-right: 20px;
  }
}

.stepChatOptions {
  --color-text: #aaa;
  --color-bg: #000;
  --color-link: #1c1cc9;
  --color-link-hover: #aaa;
  --color-info: #1c1cc9;
  --glitch-width: 50%;
  --glitch-height: 100vh;
  --gap-horizontal: 10px;
  --gap-vertical: 5px;
  --color-title: #fff;
  --time-anim: 4s;
  --delay-anim: 0s;
  --blend-mode-1: multiply;
  --blend-mode-2: multiply;
  --blend-mode-3: multiply;
  --blend-mode-4: overlay;
  --blend-mode-5: overlay;
  --blend-color-1: transparent;
  --blend-color-2: transparent;
  --blend-color-3: transparent;
  --blend-color-4: #fb909a;
  --blend-color-5: #efefef;
}

.chatCharacter {
  position: absolute;
  height: 100%;
  left: calc(50vw - 50vh);
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.message-input {
  background: #FFFFFF !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(230, 230, 230, 0.5), inset -5px 5px 10px rgba(230, 230, 230, 0.2), inset 5px -5px 10px rgba(230, 230, 230, 0.2), inset -5px -5px 10px rgba(255, 255, 255, 0.9), inset 5px 5px 13px rgba(230, 230, 230, 0.9);
  border-radius: 4px;
  width: 100%;
  height: 45px;
  padding: 10px 100px 10px 10px;
}

@use './src/styles/shadows';

.game-header {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 98;
  position: fixed;
  width: 100vw;
  left: 0;
  right: 0;
  top: 0;
  background: #efefef;
  background: linear-gradient(rgba(239,239,239,1) 60%, rgba(239,239,239,0));
}
.chat .game-header {
  
}
.progressBar {
  box-shadow: shadows.outset-shadow();

  &::before { /* for testing only. Hide for production */
    content: "";
    /* animation: loader-5 15s infinite ease; */
    background: shadows.$primary;
    border-radius: shadows.$standard-radius;
    box-shadow: shadows.raised-shadow(shadows.$primary-dark, shadows.$primary-light);
    height: 100%;

    @keyframes loader-5 {
      0% {
        width: 0%;
      }
      100% {
        width: 50%;
      }
    }
  }
}

.minimal {
  background: none;
  width: 100vw;

  .progressBar {
    box-shadow: none;
    background: none;
    border: 1px solid #212121;
  }
}

.default {
  .progressBar {
    border: none !important;
  }
}
@use 'variables';
@use 'shadows';

.cs-message-group__header {
  font-family: $font-family-mono;
  font-weight: 300;
  font-size: 18px !important;
  line-height: 40px;
  color: #999;
}
.cs-message-group__messages .cs-message .cs-message__content {
  background-color: #fefefe;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /*box-shadow: shadows.outset-shadow();*/
  border-radius: 0px 8px 8px 8px;
  justify-content: flex-start !important;
}
.cs-message-group--incoming {
  section {
    &.cs-message--outgoing {
      justify-content: flex-start !important;
    }
  }
}

.cs-message-group--outgoing .cs-message__content {
  background-color: transparent !important;
}

.cs-avatar {
  // box-shadow: shadows.sunken-shadow();
  background-color: #FFFFFF;
}

.cs-message__image-content img {
  max-width: 100% !important;
}


.cs-message-group--outgoing .cs-message-group__messages .cs-message .cs-message__content {
  background-color: #fefefe;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /*box-shadow: shadows.outset-shadow();*/
  border-radius: 8px 0px 8px 8px;
  /*color: white;*/
}

.cs-message--outgoing .cs-message__content {
  background-color: #212121;
  color: #FFFFFF;
}
$primary: #212121;
$primary-light: #333333;
$primary-dark: #111111;

$white: #fff;
$grey-1: #e0e5ec;
$grey-2: #c3c1c6;
$grey-3: #b1b1b1;
$grey-5: #9baacf;
$grey-fade-1: fade_out($grey-1, 0.1);
$grey-fade-2: fade_out($grey-2, 0.1);

$background: $grey-1;
$standard-radius: 1.2rem;
$standard-transition: 260ms;

/* === SHADOW TYPES === */
@function outset-shadow($darker-color: $grey-fade-2, $lighter-color: $white) {
  @return 0.3rem 0.3rem 0.5rem $darker-color,
    -0.2rem -0.2rem 0.4rem $lighter-color;
}

@function sunken-shadow($darker-color: $grey-fade-2, $lighter-color: $white) {
  @return -0.3rem -0.3rem 0.5rem $darker-color,
    0.2rem 0.2rem 0.4rem $lighter-color;
}

@function inset-shadow($darker-color: $grey-fade-2, $lighter-color: $white) {
  @return inset 0.2rem 0.2rem 0.5rem $darker-color,
    inset -0.2rem -0.2rem 0.5rem $lighter-color;
}

@function raised-shadow($darker-color: $grey-fade-2, $lighter-color: $white) {
  @return inset-shadow($lighter-color, $darker-color), outset-shadow();
}

@function ridge-shadow($darker-color: $grey-fade-2, $lighter-color: $white) {
  @return inset-shadow($darker-color, $lighter-color), outset-shadow();
}

/* === SHADOW TYPES ===*/
.shadow {
  &--outset,
  &--raised,
  &--inset,
  &--sunken,
  &--ridge {
    padding: 1rem 1.5rem;
    border-radius: $standard-radius;
    background: $background;
    cursor: default;
    user-select: none;
    max-width: 100%;

    @media screen and (min-width: 30em) {
      padding: 1rem 3rem;
    }
  }

  &--outset {
    box-shadow: outset-shadow();
  }

  &--raised {
    box-shadow: raised-shadow();
  }

  &--inset {
    box-shadow: inset-shadow();
  }

  &--sunken {
    box-shadow: sunken-shadow();
  }

  &--ridge {
    box-shadow: ridge-shadow();
  }
}
.page {
  margin-left: 20px;
  padding: 272px 10px 22px 10px;
  box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;
  border-radius: 106.5px 106.5px 0px 0px;
  /*filter: drop-shadow(3px 5px 5px , 2px 2px 2px #ffffff, -2px -2px 2px #0000001a);*/
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/62bd84b05a7e3f0310f4ee18/62bd85cbb3f68400113739c4//16588502671700425598.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow-y: auto;
  overflow-x: hidden;
  width: 145px;
  height: 351px;
  max-width: 200px;
  overflow: hidden;
}

.component{
  margin-top: 190px;
}
.text_1 {
  margin-left: 10px;
  margin-top: 15px;
  color: #000000;
  font-size: 20px;
  font-family: Swei Spring Sugar CJK TC;
  font-weight: 600;
  line-height: 19px;
  text-align: left;
}
.text_2 {
  margin-left: 10px;
  margin-top: 14.5px;
  align-self: flex-start;
  color: #2c2c2c;
  font-size: 12px;
  font-family: Swei Spring CJK TC, serif;
  line-height: 11px;
  text-align: left;
}
.text-wrapper {
  margin-left: 10px;
  width: 48px;
  height: 22px;
  left: 17px;
  top: 307px;
  margin-top: 10px;
  padding: 5px !important;
  /*background: rgba(196, 196, 196, 0.2);*/
  border: 1px solid #000000;
  border-radius: 2px;
}

.location {
  margin-left: 8px;
  margin-top: 200px;
  background: transparent !important;
  text-align: left !important;
}

.image {
  width: 16px;
  height: 16px;
  position: relative;
  display: inline-block !important;
}
.text {
  /*margin: 1px 0 2px 4.5px;*/
  color: #000000;
  font-size: 14px;
  font-family: Swei Spring CJK TC, serif;
  font-weight: 600;
  line-height: 13px;
  display: inline-block !important;
}
.text_3 {
  color: #2c2c2c;
  font-size: 11px;
  font-family: Swei Spring CJK TC;
  line-height: 10px;
  text-align: left;
}

.btn {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0;

  &-primary {
    padding: 15px 32px;
    margin: 30px;
    gap: 10px;
    width: 80vw;
    min-height: 50px;
  }

  &-secondary {
    background: linear-gradient(180deg, #515151 0%, #161616 100%);
    box-shadow: -5px 5px 10px rgba(212, 211, 209, 0.2), 5px -5px 10px rgba(212, 211, 209, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(212, 211, 209, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(212, 211, 209, 0.5);
    border-radius: 50px;
  }

  &-outline {
    border-radius: 50px;
    border: 1px solid #030303;
  }
}

.button {
  font-family: $font-family-sans;
}

.clickable {
  cursor: pointer;
}

.slide-button {
  border-radius: 30px !important;
  border: none !important;
  color: #999 !important;
}

.slide-button-caret {
  opacity: 0.2;
  animation: breathe 4s normal infinite;
}

.slide-button-overlay {
  border-radius: 30px !important;
  min-width: 70px;
  box-shadow: 2px 0px 8px #999;
}

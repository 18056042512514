.general-popup {
  --bs-modal-bg: #F4F3F0;
  border-radius: 20px;
}

.marker {
  background-size: cover;
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  cursor: pointer;
}

.scan {
  transform: none !important;
  max-width: none !important;
  top: none !important;
  bottom: 0;
  right: 0;
}
.scan .mapboxgl-popup-tip {
  display: hidden;
}

.missionPermissionOptions {
  --color-text: #aaa;
  --color-bg: #000;
  --color-link: #1c1cc9;
  --color-link-hover: #aaa;
  --color-info: #1c1cc9;
  --glitch-width: 100vw;
  --glitch-height: 100vh;
  --gap-horizontal: 10px;
  --gap-vertical: 5px;
  --color-title: #fff;
  --time-anim: 4s;
  --delay-anim: 2s;
  --blend-mode-1: none;
  --blend-mode-2: none;
  --blend-mode-3: none;
  --blend-mode-4: overlay;
  --blend-mode-5: overlay;
  --blend-color-1: transparent;
  --blend-color-2: transparent;
  --blend-color-3: transparent;
  --blend-color-4: #fb909a;
  --blend-color-5: #efefef;
}

.missionInfo {
  position: relative;
  z-index: 9;
}

.missionBackground {
  scale: 1.01;
}

.startButtons {
  position: absolute;
  top: 100vh;
  margin-top: -120px;
  height: 50vh;
  width: 100vw;
  z-index: 2;
  background: #efefef;
  padding: 2vh;
  text-align: center;
  box-shadow: 0px -4px 8px 8px rgba(0, 0, 0, 0.05);
}

.missionTitle {
  font-size: 4em;
}


.textfield {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3rem;
  width: 100%;

  span {
    font-size: 1.2rem;
  }

  div {
    box-shadow: inset-shadow();
    background: $white;
    border: none;
    border-radius: $standard-radius;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    width: 100%;

    .textfield__icon {
      margin-right: 0.5rem;
    }

    input {
      background: transparent;
      border: none;
      color: $primary;
      flex: 1;

      &::placeholder {
        color: $grey-2;
      }

      &:focus {
        outline: none;
      }
    }

    &:focus-within {
      box-shadow: ridge-shadow();

      .textfield__icon {
        color: $primary;
      }
    }
  }
}
::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar:horizontal {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.5rem $secondary;
}

::-webkit-scrollbar-thumb {
  &,
  &:hover {
    background: $black;
  }
}

header {
  position: absolute;
  top: 0;
  z-index: 100;
  overflow: hidden;
  padding: 0;
  margin: 0;

  //@media screen and (min-width: 500px) {
  //  width: 60ch;
  //}

  .progress {
    background-color: rgba(244, 243, 240, 0.1);
    border: 1px solid #000000;
    border-radius: 20px;
    &.chat {
      background-color: transparent;
      border: 1px solid #000000;
      border-radius: 20px;

      .progress-bar {
        background-color: #FFFFFC;
        border-radius: 20px;
      }
    }

  }

  .title {
    font-size: 16px;
    grid-column-start: 2;
    grid-column-end: 8;

    &-chat {
      grid-column-start: 2;
      grid-column-end: 7;

      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        display: block;
        text-align: left;
      }
    }

    &-exp {
      font-weight: 500;
      grid-column-start: 7;
      grid-column-end: 9;
      opacity: 0.4;
    }

    &-home {
      grid-column-start: 1;
      grid-column-end: 6;

      span {
        display: block;
        text-align: left;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
      }
    }

    &-home-exp {
      font-weight: 500;
      grid-column-start: 6;
      grid-column-end: 8;
    }

  }

  .grid-parent {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    gap: 1px;
    /* background-color: black; */
    padding: 1px;
  }

  .grid-item {
    background-color: #efefef;
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
  }

}

html, body {
  background-color: $body-color;
}

main {
  font-family: adobe-garamond-pro, serif;;
  font-weight: 600;
  background-color: $body-color;
}

section {
  margin-top: $spacer * 3;
  margin-bottom: $spacer * 3;

  @each $color, $value in $theme-colors {
    &.bg-#{$color} {
      padding-top: $spacer * 3;
      padding-bottom: $spacer * 3;
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
}

footer {
  .navbar {
    padding-top: 4rem;
    padding-bottom: 4rem;

    .navbar-brand {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 250px;
      margin-bottom: 4rem;

      @include media-breakpoint-up(md) {
        margin-top: $spacer * 0.5;
        margin-left: 0;
      }
    }

    .navbar-nav {
      .nav-link {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.border-right {
  border-right: 1px solid #212121;
}

.border-left {
  border-left: 1px solid #212121;
}

//.container {
//  max-width: 50ch !important;
//  padding: 0 !important;
//}

.progress {
  --bs-progress-bar-bg: #212121 !important;
  height: 0.5rem !important;
  margin: 0.5rem;
}

.mask-tutorial {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1110;

  .overlay {
    border-radius: 20px;
    position: absolute;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, .7);
  }

  .overlay-title {
    position: absolute;
    color: white;
    font-weight: 600;
    font-size: 24px;
  }

  .overlay-arrow {
    position: absolute;
    color: white;
  }

  .overlay-subTitle {
    position: absolute;
    color: white;
    font-weight: 500;
    font-size: 14px;
    width: 150px;
  }
}

.modal.fade.left.sidebar .modal-dialog {
  transform: translate(-125%, 0px);
  background: #F4F3F0;
  width: 70vw !important;;


}

.modal.show.left.sidebar .modal-dialog {
  transform: none;
}

.sidebar {
  position: fixed;
  right: 0;
  height: 100vh;
  //background: #F4F3F0;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, .3);
  display: grid;
  grid-template-rows: auto 1fr auto;

  .sidebar-title {
    font-weight: 600;
    font-size: 20px;
  }

  .grid-parent {
    display: grid;
    grid-template-columns: 36px 1fr 1fr 1fr 1fr 1fr;
    gap: 1px;
    background-color: black;
    padding: 1px;
  }

  .bordered-grid-item {
    background-color: #F4F3F0;
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
  }

  .others {
    grid-column-start: 2;
    grid-column-end: 7;
  }

  .service {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  .exit {
    position: absolute;
    width: calc(100% - 36px);
    bottom: 20px;
  }
}

hr {
  opacity: 1 !important;
}

.question-popup {
  --bs-modal-bg: #F4F3F0;
  justify-content: center;
  --bs-modal-margin: 0rem !important;

  .modal-dialog {
    display: flex;
    align-items: end;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
    width: 100vw;
    padding-bottom: 20px;
    //bottom: 0 !important;
    //border-radius: 20px 0;
  }

  &.collapse {
    .modal-dialog {
      padding-bottom: 0px;
    }

    .modal-content {
      min-height: 50px;
    }
  }

  .modal-content {
    min-height: 52vh;
  }
}

.chat-header-button {
  background: #F4F3F0;
  border: 1px solid #000000;
  border-radius: 100px;
  width: max-content;
}

.chat-header-exp {
  background: #F4F3F0;
  border: 1px solid #000000;
  border-radius: 100px;
  width: 87px;
  height: 32px;
}

.chat-modal-backdrop {
  opacity: 0 !important;
  display: none;
}

.chat-popup {
  --bs-modal-bg: #F4F3F0;
  --bs-backdrop-opacity: 1;
  --bs-modal-margin: 0rem !important;

  &.modal {
    top: 10%;
    bottom: 0;
    height: 90%;
  }

  .modal-dialog {
    display: flex;
    align-items: end;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
  }

  .modal-content {
    max-height: 66vh;
  }

  .modal-body {
    padding-bottom: 20px;
  }
}

body.modal-open {
  overflow: scroll !important;
}

.nav-item {


  button {
    background: #EEEADE !important;
    border-radius: 0 16px 0 0 !important;
    width: calc(100vw / 3);
    border-left: transparent !important;
    border-top: transparent !important;
    border-bottom: 1px solid rgba(116, 111, 105, 0.8) !important;
    border-right: 1px solid rgba(116, 111, 105, 0.8) !important;
    padding-left: -10px;
    color: #746F69 !important;


    &.active {
      background-color: #F4F3F0 !important;
      color: #000000 !important;
      border-right: 1px solid #000000 !important;
      border-bottom: 1px solid #F4F3F0 !important;

    }
  }
}

.nav-tabs {
  background-color: #EEEADE !important;
}

.nav-link.active {
  border-bottom: transparent;
}

.game-popup {
  --bs-modal-bg: #F4F3F0;
  --bs-backdrop-opacity: 1;
  box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;
  border-radius: 20px;
  --bs-modal-margin: 0rem !important;
  pointer-events: none;

  .modal-dialog {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
    display: flex;
    align-items: end;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
  }

  .modal-content {
    border: none;
    box-shadow: 3px 5px 10px rgba(100, 97, 89, 0.2), inset -2px -2px 4px rgba(0, 0, 0, 0.1), inset 2px 2px 4px #FFFFFF;
    border-radius: 20px;
    height: max-content;
  }
}

.form-check-input {
  height: 1.2em !important;
  &:checked {
    background-color: #212529 !important;
    border-color: #212529 !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}
